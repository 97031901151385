
.column-container{
    width:250px;
    display: flex;
    flex-direction: column;
}

.service-list{
    flex-grow: 1;
    min-height:200px;
}

.droppable-element{
    width:250px;
    min-height:200px;
}