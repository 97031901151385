
.landing {
    margin-bottom: -24px;
}

.bgimage {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(/public/assets/images/banner.jpg);
    height: 100%;
    position: relative;
}

.login-text {
    text-align: center;
    padding: 15vh 0;
    max-width: 450px;
    margin: 0 auto;
    z-index: 1000;
    position: relative;
}

.login-text h1 {
    font-size: 70px;
    font-family: Broader View;
    color: #fff;
}

.login-text span {
    font-size: 20px; 
    vertical-align: text-top;
    top: 16px;
    position: relative;
    padding-left: 5px;
}

.login-text p {
    font-size: 20px; 
    color: #fff;
}

.login-text .btn {
    max-width: 320px;
}

.evolve-graphic {
    background-image: url(/public/assets/images/EVOLVE-graphic-New.svg);
    background-repeat: no-repeat;
    background-size: 28%;
    background-position: right 50px top 50px;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
}


@media (max-width: 991px) {
    .evolve-graphic {
        display: none;
    }
 
    .bgimage {
        background-image: url("/public/assets/images/tablet-banner.jpg");
    }
}

@media (max-width: 767px) {
    .login-text h1 {
        font-size: 50px;
    }

    .bgimage {
        background-image: url("/public/assets/images/mobile-banner.jpg");
    }

    .login-text .btn {
        max-width: 250px;
    }
}