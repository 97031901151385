
.sidebar {
    display: none;
}


@media (min-width: 1281px) {
    .sidebar {
        background-color: #e8f3ff;
        min-width: 225px;
        display: block
    }

    .sidebar ul.nav {
        position: fixed;
    }

    .sidebar a.nav-link {
        padding: 10px 30px 10px 12px !important;
        font-size: 15px;
        color: #0F204B;
    }

    .sidebar .nav-item a:hover {
         background-color: #e8f3ff; 
    }

    .sidebar-sticky .nav-item .nav-link.has-icon {
        grid-template-columns: 25px 1fr;
    }

    .sidebar .fal {
        color: #0F204B;
    }
}



