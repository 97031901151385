
.serviceCategory-text {
    font-family: "Avenir Demi";
    font-size: 16px;
    color: #0f204b;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin: 20px 0 10px;
}

.category-description-text {
    font-size: 15px;
    color: #333;
    text-decoration: none;
}

.available-service-btn {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #0067c5;
    font-size: 14px;
}

