
.tabs.client {
    position: relative;
}

.client-logo {
    position: absolute;
    top: -80px;
    right: 0;
    max-width: 220px;
    max-height: 60px;
}

/* (All tab fixes) */
.tabs ul.nav-tabs>li.nav-item a.nav-link {
    top: 3px !important;
}

.nav-tabs li.nav-item a.nav-link {
    font-size: 15px !important;
}

@media (max-width: 991px) {
    .tabs ul.nav-tabs > li.nav-item {
        padding: 15px 35px 0 0;
    }

    .client-logo {
        top: -80px;
        max-height: 60px;
    }
}

@media (max-width: 767px) {
    .tabs.client { 
        margin-top: 65px;
    }
    
    .tabs ul.nav-tabs > li.nav-item {
        padding: 15px 25px 0 0;
    }

    .client-logo {
        max-width: 80px;
        top: -20px;
        left: 0;
    }
}