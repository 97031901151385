.draggable-service{
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 2px;
padding: 5px 5px 5px 15px;
border: 1px solid #bad9f7;
background-color: #ffffff;
background-size: cover;
font-family: "Avenir Demi";
font-size: 12px;
color: #0f204b;
text-decoration: none solid rgb(15, 32, 75);
line-height: 30px;

}

.draggable-service:hover{
    background-color: #0067C5;
    color:white
}

.draggable-service:focus{
    background-color: #0067C5;
    color:white;
}

.draggable-service:active{
    background-color: #0067C5;
    color:white;
}