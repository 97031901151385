/*////////////// CARDS //////////////*/

.card.services {
    padding: 0;
    border-radius: 4px !important;
    margin: 5px 0 10px;
    border: 1px solid #CCCBC9;
    transition: .2s ease;
    height: 85px;
    display: flex;
    align-items: stretch;
}

.card.services .card-body {
    padding: 13px 10px 15px 15px;
    white-space: normal;
    color: #010101;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
}

.card.services .img-section {
    width: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0067C5;
    transition: .2s ease;
}

.card.services .card-img {
    width: auto;
    height: 42px;
    margin: auto;
}

.card.services:hover, 
.card.services:focus {
    text-decoration: none;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.3);
    cursor: pointer;
    border-color: #0067C5;
}

.card.services:hover .img-section,
.card.services:focus .img-section {
    background-color: #003591;
}

