
.dropdown-toggle::after { 
    content: none; 
} 

.o-header-right {
    margin: 7px 10px 0 auto;
    float: right;
}

.logo-sm .logo-image {
    width: 110px;
    left: 0px;
}

/* EVOLVE logo */
.o-header-product {
    max-height: 22px;
    border-right: none;
}

img.logo-image {
    max-width: 150px;
}

/* Menu items divider */
li.o-header-item {
    border-right: 1px solid #0f204b;
    height: 20px;
}

li.o-header-item:last-child {
    border-right: none;
}

.o-header-link-admin {
    display: none !important
}

@media (min-width: 992px) {
	.o-header-links .o-header-item {
	    margin-bottom: 0;
	    -ms-flex-align: center;
	    align-items: center;
	    padding: 0 10px;
    }

	.o-header-links .o-header-link {
	    margin: 0 10px;
	    white-space: nowrap;
	}

    .o-header-links .o-header-link.active {
        font-family: Avenir Demi;
        font-weight: normal;
    }

    .o-header-product {
        padding-right: 25px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .o-header-links {
        display: none !important;
    }

    .o-header-link-admin {
        display: block !important
    }
}

@media (max-width: 991px) {
    .o-header-actions {
        margin-right: 0;
    }

    .o-header-right {
        margin: 0;
        float: none;
    }

    .logo-sm {
        display: none;
    }
}

@media (max-width: 767px) {
    .o-header-links {
        display: none !important;
    }

    .o-header-link-admin {
        display: block !important
    }
}
