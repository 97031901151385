.post-breadcrumb{
    margin-top: 24px;
}
.title{
    margin-bottom: 60px;
    font-size: 50px;
    color: rgb(26, 26, 26);
}

.banner{
    padding-bottom: 60px;
}

.postVideo{
    max-width: 100%;
}

.summary{
    max-width: 750px;
    margin-right: auto;
    font-size: 20px;
    font-weight: 400;
    color: rgb(26, 26, 26)
}