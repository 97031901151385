.centralize-landing {
  margin: auto;
  width: 70%;
  text-align: center;
}

.image {
  background-image: url("/public/assets/images/Plant-ecosystem.jpg");
}

body {
  height: 100%;
}