.dataListTbMainList{
    width: 100%;
    /* table-layout: fixed; */
    border-collapse: collapse;
    background: white;
    tbody
    {
        

        tr{
            line-height: 45px;
            td{
                text-align: left;
                min-width: 35px;
                padding-left: 19px;
                p{
                    line-height: 25px;
                }
            }
            /* >:last-child{
                position: sticky;
                padding: 0 13px;
                right: 0;
                text-align: center;
                box-shadow: inset 1px 0px 0px 0px #DADADA;
            }
            >:first-child{
                position: sticky;
                left: 0;
                padding: 0;
                text-align: center;
                box-shadow: inset -1px 0px 0px 0px #DADADA;
            } */
        }
    }
}

.dataListTbMainList tbody .disabledSource{
    background: #d9d9d9a6 !important; 
}

.dataListTbMainList tbody tr {
    line-height: 55px;
    }

.dataListTbMainList tbody tr td{
    padding: 0 10px;
}

.dataListTbMainList tbody tr:nth-child(odd) {
    background: #F8F7F7;
    }

.dataListTbMainList tbody  tr:hover{
    box-shadow: 0px 0px 10px lightblue;
    position: relative;
    z-index: 999;
}

.dataListTbMainList thead tr{
    border-bottom: 2px solid black;
    line-height: 50px;
    th{
        white-space: nowrap;
        font-weight: 600;
        font-size: 14px;
        text-align: left;
        padding-left: 19px;
    }
    /* >:last-child{
        width: 70px;
        padding-left: 18px;
        background-color: white;
        position: sticky;
        right: 0;
        box-shadow: inset 1px 0px 0px 0px #DADADA;
    }
    >:first-child{
        z-index: 999;
        padding-right: 18px;
        position: sticky;
        left: 0;
        background-color: white;
        box-shadow: inset -1px 0px 0px 0px #DADADA;
    } */
}

.myPage{
    margin: 15px auto;
    text-align: center;
}
.myPage li{
    list-style: none;
    display: inline-block;
    padding: 6px 10px;
    border: solid 1px rgb(193, 193, 193);
    cursor: pointer;
}
.myPage .current{
    background-color: #0067c5;
    border-color: #0067c5;
    color: white;
}
.myPage li:hover{
    background-color: #f2f2f2;
    color: black;
}
.myPage li:first-child{
    border-left: solid 1px rgb(193,193,193) !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.myPage li:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
}
.myPage li:not(:first-child){
    border-left: none;
}
.myPage li:not(:last-child){
    border-left: none;
}


.pagesDiv{
    text-align: center;   
}

.pagesDiv .pageSizeDiv{
float: left;
margin-top: 4px;
position: relative;
display: flex;
width: 100px;
border-radius: 0.25em;
overflow: hidden;
}

/* Arrow */
.pagesDiv .pageSizeDiv::after {
content: '\25BC';
position: absolute;
top: -8px;
right: -2px;
padding: 1em;
background-color: #6c757d;
transition: .25s all ease;
pointer-events: none;
color: white;
}
/* Transition */
.pagesDiv .pageSizeDiv:hover::after {
color: #f39c12;
}

/* Remove IE arrow */
.pagesDiv .pageSizeDiv select::-ms-expand {
display: none;
}

.pagesDiv .pageSizeDiv select{
    width: 80px;
    height: 40px;
    appearance: none;
    outline: 10px red;
    border: 0;
    box-shadow: none;
    flex: 1;
    padding: 0 1em;
    color: #fff;
    background-color: #6c757d;
    background-image: none;
    cursor: pointer;
}

.searchButton{
    float: left;
    height: 36px;
    border-radius: 5px;
    padding: 0px 10px;
    border: solid 1px rgb(193,193,193);
}

.noResult{
    width: 100%;
    display: table;
    text-align: center;
    padding-top: 50px;
}