@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

/***** Page Structure ******/
html {
    scroll-behavior: smooth;
    height: 100% !important;
}

#root {
    height: 100% !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.max-width {
    max-width: 1300px;
}  

@media (max-width: 1330px) and (min-width: 992px) {
    .max-width {
        padding-left: 70px;
        padding-right: 70px;
    }  
}


/***** Typography ******/
body {
    font-family: Avenir Regular,Verdana,sans-serif;
}

h4 {
    margin-bottom: 0;
}

a {
    color: #0067c5;
}

b {
    font-family: "Avenir Demi";
    font-weight: normal;
}

hr {
    border: solid #eee;
    border-width: 0 0 1px 0;
}


/**** Button Customization *****/
.btn-primary {
    background-color: #0067c5;
    border: none;
    font-family: Avenir Demi;
}

    .btn-primary:hover,
    .btn-primary:focus {
        background-color: #00407C;
    }

.btn-subtle {
    border-color: #99d9f0;
    background-color: #fff;
    color: #0067c5;
    border-radius: 0;
}

    .btn-subtle:hover, .btn-subtle:focus {
        background-color: #e5f5fb;
        border-color: #99d9f0;
        color: #0067c5;
    }

.btn-flat {
    border-color: #fff;
    background-color: #fff;
    color: #0067c5;
    border-radius: 0;
}

.btn-flat:hover, .btn-flat:focus {
    background-color: #e5f5fb;
    color: #0067c5;
}

.h5, h5 {
    font-size: 1.25rem;
}

.h4, h4 {
    font-size: 1.5rem;
}

.btn {
    border-radius: .25rem;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    user-select: none;
    vertical-align: middle;
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff;
}

.form-control {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #495057;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    height: calc(1.5em + .75rem + 2px);
    line-height: 1.5;
    padding: .375rem .75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.sortDownDisabled, .sortUpDisabled {
    color: #d3d3d3;
}