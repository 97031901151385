.button-container {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.Toastify .Toastify__toast-container {
  width: auto;
  max-width: 1000px;
}

.Toastify .Toastify__toast-body {
  white-space: pre-wrap !important;
}

/*** Breadcrumbs ****/
.MuiBreadcrumbs-ol {
    list-style-type: none;
    padding-left: 0;
}

.MuiBreadcrumbs-li,
.MuiBreadcrumbs-li-last.MuiBreadcrumbs-li-last,
.MuiBreadcrumbs-separator {
    font-family: "Avenir Demi";
    font-size: 26px !important;
    color: #0f204b !important;
    display: inline-block;
}

li.MuiBreadcrumbs-separator {
    width: 32px;
    margin: 0 5px;
}