.admin-managepost-col {
  display: block;
/*  width: 60% !important;*/
  margin: auto;
}

h5 {
    text-align:center;
    padding-bottom:20px;
}


