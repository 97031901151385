
/***** Search filter *****/

.searchfilter .basic-multi-select .select__control {  
  line-height: 36px;
  border-color: #1A1A1A;
}

.searchfilter .basic-multi-select .select__menu {  
  background-color: #fff;
  margin-top: 1px;
  z-index: 1000;
}

.searchfilter .basic-multi-select .select__placeholder {
  color:#1A1A1A;
}

.searchfilter .basic-multi-select svg {
  font-weight: 300;
  color:#1A1A1A;
}

.searchfilter button {
  width: 100%;
  height: 50px;
} 

/***** Posts *****/

.posts {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.posts .post {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  width: 47%;
  margin: 0 1.5% 30px; 
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.16);
  transition: .2s ease;
}

.posts .post:hover, 
.posts .post:focus {
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0,0,0,0.3);
}

.post .image {
  height: 277px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.post .postVideo {
  position: relative;
  width: 100%;
  height: 277px;
}

.post .postVideo video {
  width: 100%;
  height: 100%;
}

.post .postVideo video.play ~ div{
  display: none;
}

.post .postVideo div{
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  display: block;
  width: 120px;
  height: 120px;
  /* background-color: rgba(0, 0, 0, .45);
  border-radius: 50%; */
}

.post .postVideo div .MuiSvgIcon-fontSizeLarge{
  font-size: 120px;
  fill: rgba(0,0,0, 0.45);
}

.post .postVideo div .MuiSvgIcon-fontSizeLarge:hover{
  fill: rgb(21, 27, 23);
}

.post .content {
  padding: 30px;
}

.post .content h3 {  
  margin-bottom: 20px;
  font-size: 20px;
}

.post .content .description {
  margin-bottom: 20px;
}

.post .content .operations {
  margin-bottom: 20px;
}

.post .content .operations a {
  color: #0067c5;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  background-color: #FFF;
  border-width: 0;
  text-decoration: none;
}

.post .content .operations a:hover {  
  text-decoration: none;
}

.post .content .operations a::after {
  font-size: 13px;
  color: #0067C5;
  content: "\f178";
  margin-left: 10px;  
  font-family: Font Awesome\ 5 Pro;
  font-weight: 900;
  font-size: medium;
  vertical-align: middle;
}

@media (max-width: 767px) {
    .posts .post {
        width: 97%;
    }
}

/**** Banner body *****/
/*.banner {
  padding-left: 100px;
  font-size: larger;
  color: white;
}

.banner-card.card {
    border-radius: 0;
}

.banner-card.card:hover,
.banner-card.card:focus {
    box-shadow: none;
    border-color: transparent;
    cursor: default;
}

.card-img-overlay {
  background-image: linear-gradient(
    to right,
    rgba(0,0,0,0.4),
    rgba(0,0,0,0)
  );
  border-radius: 0;
}

.banner-image {
  object-fit: cover;
  height: 400px;
}*/


/**** Banner text *****/

/*.banner-title {
  font-size: 2rem !important;
}

.summary {
  white-space: pre-line;
}

.summary img {
  width: 100%;
}*/


/*@media (max-width: 767px) {
  .selector {
    width: 100%;
  }
}*/
