/** {
  margin: 0;
}*/

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.nothing-display {
    text-align: center;
    margin-top: 20%;
}