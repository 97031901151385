.dnd-container{
    display: flex !important;
    margin-left:-30px;

}
.client-service-button{
    width: 100%;
    margin-bottom: 8px !important;
}

.update-client-btn{
    vertical-align: middle;
}

.tab-content .tab-pane.usergroup {
    position: relative;
}

.loadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    text-align: center;
    z-index: 999;
    opacity: 0.7;
}

.loadingContainer .nothing-display {
    margin-top: 10%
}


/* #image {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    transform-origin: 0 0 0;
    box-shadow: none;
    word-wrap: break-word;
    overflow: hidden;
    white-space: pre;
    font-family: "C-Avenir Next Medium-12", Arial !important;
    top: auto;
    left: auto;
    pointer-events: all;
    cursor: pointer;
    opacity: 1;
    mix-blend-mode: normal;
    border-radius: 0px;
    border-color: rgb(153, 217, 240);
    background-color: rgb(255, 255, 255);
    color: rgb(0, 103, 197);
    font-weight: normal;
    padding: 6px 10px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    background-position: center center;
    background-repeat: repeat;
    background-size: auto;
    border-width: 1px;
    border-style: solid;
} */