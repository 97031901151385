
.card.admin {
    text-align: center;
}

.admin-card-icon{
    margin:auto;
    text-align: center;
    width:2em !important;
    height:2em !important;
    color:#0067c5
}

.card.admin:hover, .card.admin:focus {
    border-color: #dfdfdf;
}


