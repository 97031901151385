.admin-clients-container {
    width: 95% !important;
    margin: auto;
  
  }
  .admin-clients-tab-container.row {
    width: 100% !important;
    display: table;
    margin:auto;
  }
  .admin-clients-col {
    display: table-cell;
    width: 80% !important;
  }
  
  .col-admin-sidebar {
    display: flex;
    flex-direction: column;
    max-width: 250px;
  }
  
  .admin-sidebar {
    top: 68px;
    max-width: 250px;
    background-color: #e8f3ff;
  }
  
 
  
  /* Sidebar menu items */
  .admin-sidebar .nav-link,
  .admin-sidebar .nav-link .fal {
    color: #0f204b;
    font-size: 16px;
  }
  
  .admin-sidebar .nav-item a:hover,
  .admin-sidebar .nav-item a:focus {
    background-color: #e8f3ff;
  }
  
  /* Active item */
  .admin-sidebar .nav-item .nav-link.active {
    background-color: #d3e7fa;
    color: #0067c5;
    font-family: "Avenir Demi";
  }
  
  .admin-sidebar .nav-item .nav-link.active .fal {
    color: #0067c5;
  }
  