.helpInline {
  display: inline;
}

#postImage {
  display:block;
  border-style: solid;
  border-width: thin;
  border-radius: 2%;
  margin: 10px auto;
  max-width: 90%;
}

#imageUpload{
  display: "none";
}

#postVideo {
  display:block;
  border-style: solid;
  border-width: thin;
  border-radius: 2%;
  margin: 10px auto;
  max-width: 90%;
}

.file-drop-target {
  display: flex;
  justify-content: center;
  border: 2px dotted #a9a9a9 !important;
  height: 100px;
  text-align: center;
  align-items: center;
  margin-bottom: 4px;
}

.file-drop>.file-drop-target.file-drop-dragging-over-frame {
  /* overlay a mask when dragging over the frame */
  border: none;
  background-color: #ffffff;
  box-shadow: none;
  z-index: 50;
  opacity: 1.0;
  /* --------------- */

  /* typography */
  color: black;
  /* --------------- */
}

.file-drop>.file-drop-target.file-drop-dragging-over-target {
  /* change color when dragging over the target */
  color: darkgray;
  box-shadow: 0 0 8px 2px #3bc2d4 inset;
}
